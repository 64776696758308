<template>
  <div class="order-list">
    <List
      model:loading="loading"
      :finished="finished"
      finished-text=""
      @load="onLoad"
      v-if="status != 8"
    >
      <Tabs @click-tab="onClickTab" v-model:active="active">
        <Tab :title="item.title" v-for="(item,index) in tabList" :key="index">
            <div v-if="orderList.length">
              <div class="list" v-for="(v, i) in orderList" :key="i" @click="jump(v.orderId)">
                  <div class="order-list-item">
                  <div class="flex align-content order-list-item-title">
                    <span class="span1 m-f-28">{{v.shopName}}</span>
                    <span class="m-f-24 red">{{v.resultStatusStr}}</span>
                  </div>
                  <OrderItem :orderItem="v" />
                  <ButtonList :orderItem="v" type="orderList" />
                </div>
              </div>
            </div>
            <Empty image="https://file.senlianshop.com/orderStatus/orderKzt.png" v-else>
              <p class="tip">还没有订单，先去逛逛！</p>
              <div class="gohome" @click="router.push('/home')">去逛逛</div>
            </Empty>
        </Tab>
      </Tabs>
      </List>
  </div>
</template>
<script setup>
import {
  Tab, Tabs, List, Empty,
} from 'vant';
import { ref, onMounted, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import OrderItem from '@com/OrderItem.vue';
import ButtonList from '@com/ButtonList.vue';
import API from '@/config/api';
/** 变量 */
const BuriedPoint = inject('$buried_point');
const tabList = ref([
  {
    title: '待支付',
    id: 2,
  },
  {
    title: '待发货',
    id: 3,
  },
  {
    title: '待收货',
    id: 4,
  },
  {
    title: '待归还',
    id: 5,
  },
  {
    title: '已完成',
    id: 6,
  },
  {
    title: '已取消',
    id: 7,
  },
]);
const orderList = ref([]);
const loading = ref(false);
const finished = ref(false);
const page = ref(1);
const status = ref(useRoute().query.status);
const active = ref(Number(useRoute().query.status) - 2);
const router = useRouter();
/** 函数 */
const onorderList = async () => {
  const data = await API.orderListV3({
    page: page.value,
    size: 10,
    tab: status.value,
  }) || { records: [] };
  loading.value = false;
  orderList.value = [...orderList.value, ...data.records];
  if (data.records.length < 10) {
    finished.value = true;
  }
};

const jump = (orderId) => {
  router.push(`/order_detail?orderId=${orderId}`);
};

const onLoad = () => {
  onorderList();
  page.value += 1;
};

const onClickTab = ({ title }) => {
  page.value = 1;
  orderList.value = [];
  /* eslint-disable prefer-destructuring */
  // eslint-disable-next-line no-unused-expressions
  status.value = tabList.value.filter((item) => item.title === title)[0].id;
  onorderList();
};

onMounted(() => {
  BuriedPoint.view_myOrder({ channelTag: localStorage.channel });
});
</script>
<style lang="scss" scoped>
.order-list {
  .list {
    padding: 0 24px;
  }
  &-item {
    margin-top: 30px;
    padding: 24px 24px 0 24px;
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    &-title {
      margin-bottom: 10px;
    }
  }
}
.gohome {
  width: 220px;
  height: 80px;
  border-radius: 30px;
  padding: 0 20px;
  box-sizing: border-box;
  line-height: 80px;
  text-align: center;
  margin-right: 16px;
  font-size: 28px;
  border: 1px solid #DDDDDD;
}
.tip {
  color: $content-garycolor;
  margin-bottom: 10px;
}
/deep/ .van-tabs__line {
  background: $hightlight-fontColor;
}
/deep/ .van-tab--active span {
  color: $hightlight-fontColor;
}
</style>
